import { ref, watch, computed, reactive } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const permissions = ref([])
const filteredPermissions = ref([])

export default function usePermission() {
  // Use toast
  const toast = useToast()

  const refPermissionTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'action', sortable: true },
    { key: 'subject', sortable: true },
    { key: 'actions',  },
  ]
  const perPage = ref(10)
  const totalPermissions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refPermissionTable.value ? refPermissionTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPermissions.value,
    }
  })

  const refetchData = () => {
    console.log("refectchData")
    refPermissionTable.value.refresh()
  }
 
  const refreshData = () => {
    permissions.value = []
    fetchPermissions()
  }

  const resolvePermissionStatusBadge = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = permissions.value.filter(
    permission => permission.title.toLowerCase().includes(queryLowered) ||
    permission.code.toLowerCase().includes(queryLowered) || permission.description.toLowerCase().includes(queryLowered))
    
    filteredPermissions.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalPermissions.value = filteredData.length
    refetchData()
  })

  function setPermission(permission) {
    permissions.value = permissions.value.filter(x => x.id != permission.id);
    permissions.value.push(permission);
    permissions.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredPermissions.value = paginateArray(permissions.value, perPage.value, currentPage.value)
  }

  function removePermission(permissionId) {
    permissions.value = permissions.value.filter(x => x.id != permissionId);
    permissions.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredPermissions.value = paginateArray(permissions.value, perPage.value, currentPage.value)
  }

  const fetchPermissions = () => {
    if(permissions.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.PERMISSION)
      .then(({data}) => {
        permissions.value = data;
        filteredPermissions.value = paginateArray(permissions.value, perPage.value, currentPage.value)
        totalPermissions.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching permissions',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const addPermission = (data) => {
    delete data.id
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(routes.PERMISSION, data)
        .then(({ data }) => {
          setPermission(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Permission added successfully`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }
  const updatePermission = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.put(routes.PERMISSION+`/${data.id}`, data)
        .then(({ data }) => {
          setPermission(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
}
  
    const deletePermission = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.delete(routes.PERMISSION+`/${data.id}`, data)
        .then(({ data }) => {
          // removePermission(data.id)
          toast({
            component: ToastificationContent,
            props: {
              title: `Delete Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }

  return {
    fetchPermissions,
    addPermission,
    updatePermission,
    deletePermission,
    tableColumns,
    perPage,
    currentPage,
    totalPermissions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPermissionTable,

    setPermission,
    removePermission,
    refetchData,
    refreshData,
    resolvePermissionStatusBadge,
    permissions: computed(() => filteredPermissions.value)
  }
}
